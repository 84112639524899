* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  color: #00f;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  letter-spacing: .025em;
  background-color: #87ceeb;
  margin: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: max(4vw, 30px);
}

#root {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: .5rem 1rem;
  display: flex;
}

.current {
  align-self: flex-end;
}

/*# sourceMappingURL=index.e9d3a3dd.css.map */
