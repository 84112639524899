* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: skyblue;
  color: blue;
  font-size: max(4vw, 30px);
  text-transform: uppercase;
  font-family: 'Helvetica Neue', Helvetica, Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.025em;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0.5rem 1rem;
}

.current {
  align-self: flex-end;
}